import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import SEO from '../components/seo';

const App = ({ pageContext }) => {
    const location = useLocation();

    const { markdown } = pageContext;
    const { title, body, excerpt, dateUpdated, datePublished } = markdown;

    const components = {
        a: ({ href, children, className }) => {
            if (href.match(/^\/|#/) || href.includes(location.host)) {
                return <Link to={href} className={className}>{children}</Link>;
            }

            return <a href={href} className={className} target="_blank">{children}</a>;
        },
    };

    return (
        <Layout>
            <SEO title={title} description={excerpt} dateModified={dateUpdated} datePublished={datePublished} article />
            <article id="content" className="lg:max-w-screen-md px-4 mx-auto pt-28">
                <MDXProvider components={components}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </article>
        </Layout>
    );
};

export default App;
